<template>
  <div>
    <v-container grid-list-lg fluid>
        <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
            <v-layout row wrap>
                <v-flex lg4 md12 sm12 pr-6 pl-lg-6>
                    <v-layout row wrap justify-end>
                        <v-menu
                            v-model="menuStart"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-model="computedStartDateFormatted"
                                label="Từ ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dateRange.startDate" no-title @input="menuStart = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg4 md12 sm12 pr-6>
                    <v-layout row wrap justify-end>
                        <v-menu
                            v-model="menuEnd"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-model="computedEndDateFormatted"
                                label="Đến ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dateRange.endDate" no-title @input="menuEnd = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg4 md12 sm12 pr-6>
                    <v-btn @click="filter_data" color="#a77800" outlined>Xem</v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex lg12 md12 sm12 xs12>
            <v-layout row wrap>
                <v-flex lg12 md12 sm12 xs12>
                    <highcharts :options="chartOptions"></highcharts>
                </v-flex>
            </v-layout>
        </v-flex>
        
        <v-flex lg12>
            <v-card class="mb-8">
                <v-toolbar flat color="blue-grey" dark>
                    <v-toolbar-title>Tổng giao dịch</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-layout row wrap>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-data-table fixed-header height="750px"   :headers="headers" hide-default-footer :sortable="false" :items="items" :server-items-length="items ? items.length : 0" :loading="loading" :mobile-breakpoint="100">
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{item.ReportMonth.toString().replace(/(\d{4})(\d{2})/, "$2-$1")}}</td>
                                        <td class="text-end">{{ item.OrderRevenue | currency}}</td>
                                        <td class="text-end">{{ item.DepositRevenue | currency}}</td>
                                        <td class="text-end">{{ item.PayRevenue | currency}}</td>
                                        <td class="text-end">{{ item.ComplainAmount | currency}}</td>
                                        <td class="text-end">{{ item.TotalRevenue | currency}}</td>
                                    </tr>
                                </template>
                                <template v-slot:body.append v-if="summary">
                                    <tr>
                                        <td class="font-weight-bold">TỔNG</td>
                                        <td class="font-weight-bold text-end">{{ summary.OrderRevenue | currency }}</td>
                                        <td class="font-weight-bold text-end">{{ summary.DepositRevenue | currency }}</td>
                                        <td class="font-weight-bold text-end">{{ summary.PayRevenue | currency }}</td>
                                        <td class="font-weight-bold text-end">{{ summary.ComplainAmount | currency }}</td>
                                        <td class="font-weight-bold text-end">{{ summary.TotalRevenue | currency }}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-flex>

        <v-flex lg12>
            <v-card class="mb-8">
                <v-toolbar flat color="blue-grey" dark>
                    <v-toolbar-title>Doanh thu theo nhân viên</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-layout row wrap>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-data-table fixed-header height="750px"   :headers="summary_headers" hide-default-footer :sortable="false" :items="staff_summary" :server-items-length="staff_summary ? staff_summary.length : 0" :loading="loading" :mobile-breakpoint="100">
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{item.StaffName}}</td>
                                        <td class="text-end">{{ item.RevenueCommissionVND | currency}}</td>
                                        <td class="text-end">{{ item.OrderCommission | currency}}</td>
                                        <td class="text-end">{{ item.OrderFeeCommission | currency}}</td>
                                        <td class="text-end">{{ item.DepositFeeCommission | currency}}</td>
                                        <td class="text-end">{{ item.TotalValue | currency}}</td>
                                    </tr>
                                </template>
                                <template v-slot:body.append v-if="staff_total">
                                    <tr>
                                        <td class="font-weight-bold">TỔNG</td>
                                        <td class="font-weight-bold text-end">{{ staff_total.RevenueCommissionVND | currency }}</td>
                                        <td class="font-weight-bold text-end">{{ staff_total.OrderCommission | currency }}</td>
                                        <td class="font-weight-bold text-end">{{ staff_total.OrderFeeCommission | currency }}</td>
                                        <td class="font-weight-bold text-end">{{ staff_total.DepositFeeCommission | currency }}</td>
                                        <td class="font-weight-bold text-end">{{ staff_total.TotalValue | currency }}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import {Chart} from 'highcharts-vue';
import { mapState } from "vuex";
import configs from "../../configs";
import _ from "lodash";
import moment from "moment"
export default {
    components: {
        highcharts: Chart 
    },
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            viewName: "bao-cao-doanh-thu",
            dateRange: {
                startDate: moment.utc(Date.now()).startOf('month').toISOString().substr(0, 10),
                endDate: moment.utc(Date.now()).endOf('month').toISOString().substr(0, 10),
            },
            menuStart: false,
            menuEnd: false,
            headers: [
                {
                text: "Tháng",
                value: "ReportMonth",
                sortable: false,
                filterable: true,
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Mua hộ",
                value: "OrderRevenue",
                sortable: false,
                filterable: true,
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Ký gửi",
                value: "DepositRevenue",
                sortable: false,
                filterable: true,
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Thanh toán hộ",
                value: "PayRevenue",
                sortable: false,
                filterable: true,
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Hoàn khách",
                value: "ComplainAmount",
                sortable: false,
                filterable: true,
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Tổng giao dịch",
                value: "TotalRevenue",
                sortable: false,
                filterable: true,
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                }
            ],
            summary_headers: [
                {
                text: "Nhân viên",
                value: "StaffName",
                dataType: configs.DATA_TYPE["String"]
                },
                {
                text: "Chênh lệch tiền hàng",
                value: "RevenueCommissionVND",
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Doanh thu Order",
                value: "OrderCommission",
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Cước VCQT đơn Order",
                value: "OrderFeeCommission",
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Cước VCQT đơn Ký gửi",
                value: "DepositFeeCommission",
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Tổng doanh thu",
                value: "TotalValue",
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                }
            ],
        };
    },
    computed: {
        ...mapState({
            items: state => state.report.orderRevenueV2.data,
            staff_summary: state => state.report.orderRevenueV2.summary,
            loading: state => state.report.loading
        }),
        computedStartDateFormatted () {
            return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
        },
        computedEndDateFormatted () {
            return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        summary() {
            var ret = {
                'OrderRevenue': _.sumBy(this.items, 'OrderRevenue'),
                'DepositRevenue': _.sumBy(this.items, 'DepositRevenue'),
                'PayRevenue': _.sumBy(this.items, 'PayRevenue'),
                'ComplainAmount': _.sumBy(this.items, 'ComplainAmount'),
                'TotalRevenue': _.sumBy(this.items, 'TotalRevenue'),
            };
            return ret;
        },
        staff_total() {
            var ret = {
                'RevenueCommissionVND': _.sumBy(this.staff_summary, 'RevenueCommissionVND'),
                'OrderCommission': _.sumBy(this.staff_summary, 'OrderCommission'),
                'OrderFeeCommission': _.sumBy(this.staff_summary, 'OrderFeeCommission'),
                'DepositFeeCommission': _.sumBy(this.staff_summary, 'DepositFeeCommission'),
                'TotalValue': _.sumBy(this.staff_summary, 'TotalValue'),
            };
            return ret;
        },
        chartOptions() {
            var dataChart =  _.sortBy(this.items,'ReportMonth');
            var ret = {
                chart: { type: 'line' },
                title: { text: 'Doanh thu' },
                credits: { enabled: false },
                tooltip: {shared: true},
                xAxis: {
                    categories: _.map(dataChart, function(x) {return x.ReportMonth.toString().replace(/(\d{4})(\d{2})/, "$2-$1")})
                },
                yAxis: {
                    title: {
                        text: 'Tổng tiền (VNĐ)'
                    }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [
                    { name: 'Mua hộ', data: _.map(dataChart, 'OrderRevenue') },
                    { name: 'Ký gửi', data: _.map(dataChart, 'DepositRevenue') },
                    { name: 'TT hộ', data: _.map(dataChart, 'PayRevenue') },
                    { name: 'Hoàn khách', data: _.map(dataChart, 'ComplainAmount') },
                    { name: 'Tổng giao dịch', data: _.map(dataChart, 'TotalRevenue') },
                ]
            };

            return ret;
        }
    },
    methods: {
        filter_data: function () {
            var param = {
                startTime: moment(this.dateRange.startDate).format(configs.SQL_DATE_FORMAT),
                endTime: moment(this.dateRange.endDate).format(configs.SQL_DATE_FORMAT)
            };
            this.$store.dispatch("report/getOrderRevenueV2Report", param);
        }
    },
    mounted() {
        this.filter_data();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    }
};
</script>
<style scoped>
.rate-p {
    margin-bottom: 0px;
    margin-top: 16px;
}
.rate-p2 {
    margin-bottom: 16px;
    margin-top: 8px;
    color: rgb(168, 168, 168);
}
</style>